.player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  color: white;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  line-height: 2em;
  font-size: 1.5em;
  z-index: 5;
}

.player-overlay.overlay-imgs {
  display: flex;
  justify-content: center;
  height: auto;
  pointer-events: none;
}

.player-overlay.overlay-imgs .overlay-img {
  margin-right: 30px;
  height: 75px;
  pointer-events: all;
}

@media only screen and (max-width: 950px) {
  .player-overlay.overlay-imgs .overlay-img {
    height: 50px;
  }
}

@media only screen and (max-width: 650px) {
  .player-overlay.overlay-imgs .overlay-img {
    height: 30px;
  }
}
